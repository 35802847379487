import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { issuingAPI } from '../services/Axios';
import useApiHook from './useAPI';

function useMerchants() {
  const { tagCardBrands } = useSelector((state) => state.tag);
  const fetchMerchants = useCallback(async () => {
    return await issuingAPI.get(`card-brand/${tagCardBrands}/merchants`);
  }, [tagCardBrands]);

  const [result, fetch] = useApiHook(fetchMerchants);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    merchants: result?.data?.data || [],
    fetch,
    isLoading: result.loading,
  };
}

function useCreateMerchant() {
  const { tagCardBrands } = useSelector((state) => state.tag);

  async function createMerchant(payload) {
    const response = await issuingAPI.post(
      `card-brand/${tagCardBrands}/merchants`,
      payload,
    );

    return response.data;
  }

  return {
    createMerchant,
  };
}

export { useMerchants, useCreateMerchant };
