import { useCallback, useState } from 'react';

const useApiHook = (mainFunction) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const callable = useCallback(
    async (data) => {
      try {
        setLoading(true);
        setError(null);
        setSuccess(false);
        const response = await mainFunction(data);
        if (!response) {
          throw new Error('No call response received');
        }
        setData(response.data);
        setLoading(false);
        setSuccess(true);

        return response;
      } catch (error) {
        setError(error);
        setSuccess(false);
        setLoading(false);
      }
    },
    [mainFunction],
  );

  const clearErrors = useCallback(() => {
    setError(null);
  }, []);

  return [{ data, setData, loading, error, clearErrors, success }, callable];
};

export default useApiHook;
